import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import commonApi from "./store/commonApi";
import "@/assets/fonts/typefacejgj.ttf";
import "@/assets/icon/iconfont.css";
import "./assets/icon/iconfont.js";
import filters from "@/filters/index";
Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName]);
});
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "@/assets/theme-scss/theme/index.css";
import "element-ui/lib/theme-chalk/display.css"; //用于监听视口大小影藏元素
ElementUI.Tree.props.expandOnClickNode.default = false; //不允许ESC关闭dialog
ElementUI.Dialog.props.closeOnClickModal.default = false; //不允许点击dialog内容外关闭
// ElementUI.Dialog.props.modal.default = false; //dialog去掉遮罩层
ElementUI.Input.props.clearable.default = true; //输入框清空按钮
// ElementUI.Drawer.props.wrapperClosable.default = false; //抽屉不允许点击内容外关闭
ElementUI.Drawer.props.closeOnPressEscape.default = false; //抽屉禁用ESC
// ElementUI.Drawer.props.modal.default = false; //抽屉去掉遮罩层
ElementUI.InputNumber.props.controlsPosition.default = "right"; //数字输入框按钮向右
// ElementUI.Select.props.popperAppendToBody.default = false;
Vue.use(ElementUI);
Vue.prototype.commonApi = commonApi;
import "@/assets/style/public.css";
Vue.config.productionTip = false;

import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
Vue.use(mavonEditor);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
