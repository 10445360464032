<template>
  <div class="public">
    <div class="topFixed" v-if="!isJgj">
      <div class="topContent">
        <home-top></home-top>
        <home-header></home-header>
      </div>
    </div>
    <router-view :class="{ view: !isJgj }"></router-view>
    <home-footer v-if="!isJgj"></home-footer>
    <fixed-btn v-if="!isJgj"></fixed-btn>
  </div>
</template>

<script>
import homeTop from "./component/homeTop";
import homeHeader from "./component/homeHeader";
import homeFooter from "@/components/homeFooter";
import fixedBtn from "./component/fixedBtn";
export default {
  name: "layout",
  components: {
    homeTop,
    homeHeader,
    homeFooter,
    fixedBtn,
  },
  data() {
    return {
      user: {},
      isJgj: false,
    };
  },

  mounted() {
    let url = window.location.hash;
    if (url.search("jgj") != "-1") {
      this.isJgj = true;
    } else {
      this.isJgj = false;
      if (JSON.parse(localStorage.getItem("user"))) {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.getshopsNum();
      } else {
        this.user = {};
      }
    }
  },

  methods: {
    getshopsNum() {
      // request({
      //   url: "/api/customer/shopping/numCount",
      //   method: "post",
      // }).then((res) => {
      //   console.log(res);
      // });
      this.$store.dispatch("app/shopNum").then(() => {});
      this.$store.dispatch("app/messageNum").then(() => {});
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.public {
  position: relative;
  .topFixed {
    height: 177px !important;
    .topContent {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
    }
  }
  .view {
    width: 1200px;
    min-height: calc(100vh - 351px);
    margin: 0 auto;
  }
}
</style>
