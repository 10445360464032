<template>
  <div class="topStyle">
    <section>
      <div>
        <span>欢迎登录金广嘉产业网！</span>
        <span
          >&emsp;服务热线：<font>027-8228-0285</font>&emsp;<font
            >027-8228-0385</font
          ></span
        >
      </div>
      <div v-if="!ishow">
        <el-popover placement="top" title="" width="150" trigger="hover">
          <div>
            <img
              src="/img/twodimensionalcode.jpg"
              width="150px"
              height="150px"
              alt=""
            />
            <div class="twodimensionalcodeStyle">扫码关注微信小程序</div>
          </div>
          <el-button slot="reference" type="text"
            ><i class="el-icon-mobile-phone"></i
          ></el-button>
        </el-popover>
        <font>&emsp;|&emsp;</font>
        <el-button @click="login" type="text">登录</el-button>
        <font>&emsp;|&emsp;</font>
        <el-button class="flashStyle" @click="register" type="text"
          >注册</el-button
        >
      </div>
      <div v-else style="display: flex">
        <el-button type="text" @click="userCenter">{{
          user.modelType == 1 ? "管理中心" : "旺铺管理"
        }}</el-button>
        <el-divider
          direction="vertical"
          v-if="user.modelType != 1"
          class="dividerStyle"
        ></el-divider>
        <el-button type="text" v-if="user.modelType != 1" @click="purchaseOrder"
          >采购订单</el-button
        >
        <el-divider
          direction="vertical"
          v-if="user.modelType != 1"
          class="dividerStyle"
        ></el-divider>
        <el-button type="text" v-if="user.modelType != 1" @click="saleOrder"
          >销售订单</el-button
        >
        <el-divider direction="vertical" class="dividerStyle"></el-divider>
        <el-button type="text" @click="commodityManagement">商品管理</el-button>
        <el-divider direction="vertical" class="dividerStyle"></el-divider>
        <el-button type="text" @click="customerManagement">{{
          user.modelType == 1 ? "用户管理" : "客户管理"
        }}</el-button>
        <el-divider direction="vertical" class="dividerStyle"></el-divider>
        <el-dropdown
          size="small"
          class="dropdownStyle"
          v-if="user.modelType != 1"
        >
          <span class="btn-text">用户设置</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="personalData">
              个人资料
            </el-dropdown-item>
            <el-dropdown-item @click.native="shopInformation"
              >商铺资料</el-dropdown-item
            >
            <!-- <el-dropdown-item @click.native="termsSale"
              >销售条款</el-dropdown-item
            > -->
            <el-dropdown-item @click.native="changePassword"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click.native="addressDelivery"
              >收货地址</el-dropdown-item
            >
          </el-dropdown-menu>
          <el-dropdown-menu></el-dropdown-menu>
        </el-dropdown>
        <el-button
          type="text"
          @click.native="platformSetup"
          v-if="user.modelType == 1"
          >平台设置</el-button
        >
        <el-divider direction="vertical" class="dividerStyle"></el-divider>
        <el-badge
          :is-dot="$store.state.app.messagenum.unReadNum > 0"
          class="top_item"
        >
          <el-button type="text" @click="customerMessage">消息通知</el-button>
        </el-badge>
        <el-divider direction="vertical" class="dividerStyle"></el-divider>
        <el-popover placement="top" title="" width="150" trigger="hover">
          <div>
            <img
              src="/img/twodimensionalcode.jpg"
              width="150px"
              height="150px"
              alt=""
            />
            <div class="twodimensionalcodeStyle">扫码关注微信小程序</div>
          </div>
          <el-button slot="reference" type="text"
            ><i class="el-icon-mobile-phone"></i
          ></el-button>
        </el-popover>
        <el-divider direction="vertical" class="dividerStyle"></el-divider>
        <el-dropdown size="small" class="dropdownStyle">
          <span class="btn-text">当前用户：{{ user.name }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logOff"> 退出 </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </section>

    <!-- 查看消息抽屉 -->
    <el-drawer
      class="order_drawer"
      :modal="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="messageShow"
      @close="drawerClose"
      direction="rtl"
      size="60%"
      style="z-index: 2500"
    >
      <div slot="title" class="dialog_title">
        消息通知<span
          >(共<font style="color: #f22020">{{
            $store.state.app.messagenum.total
          }}</font
          >条)</span
        >
      </div>

      <div class="sort">
        <div>
          <el-radio-group v-model="radio" @change="statusChange">
            <el-radio-button :label="-1">全部消息</el-radio-button>

            <el-radio-button :label="0">
              <el-badge
                v-if="radio != 0"
                :value="$store.state.app.messagenum.unReadNum"
                :max="99"
                class="item"
              >
                <span>未读</span>
              </el-badge>
              <span v-else>未读</span>
            </el-radio-button>

            <el-radio-button :label="1">
              <el-badge
                v-if="radio != 1"
                :value="$store.state.app.messagenum.alreadyReadNum"
                :max="99"
                class="item"
              >
                <span>已读</span>
              </el-badge>
              <span v-else>已读</span>
            </el-radio-button>
          </el-radio-group>
        </div>
        <el-button type="primary" @click="readAll">全部已读</el-button>
      </div>

      <div style="min-height: 579px">
        <el-card
          class="mess_card"
          v-for="(item, index) in messageObj.records"
          :key="index"
        >
          <h4>
            <span>尊敬的用户,您好!</span>
            <el-button
              :disabled="item.status == 1"
              type="text"
              @click="isRead(item)"
              >{{ item.statusText }}</el-button
            >
          </h4>
          <span>{{ item.message }}</span>
        </el-card>
      </div>

      <div class="pagination">
        <el-button size="small" @click="pervious">上一页</el-button>
        <el-pagination
          background
          ref="pageGroup"
          layout="pager"
          :total="messageObj.pages * 10"
          :current-page="currentpage"
          @current-change="pageBtn"
        >
        </el-pagination>
        <el-button size="small" @click="next">下一页</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { request } from "@/api/service";
import util from "@/libs/util.js";
export default {
  name: "homeTop",

  data() {
    return {
      ishow: false,
      user: {},
      messageShow: false,

      currentpage: 1, //当前页
      size: 15,
      radio: -1,
      messageObj: {},
    };
  },

  watch: {
    "$route.path"(toPath, fromPath) {
      if (this.messageShow) {
        this.messageShow = false;
      }
    },
  },
  mounted() {
    // console.log(JSON.parse(localStorage.getItem("user")));
    if (JSON.parse(localStorage.getItem("user"))) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.ishow = true;
      if (this.$route.name) {
        if (this.$route.name == "login") {
          this.user = {};
          this.ishow = false;
        }
      }
    } else {
      this.user = {};
      this.ishow = false;
    }
  },
  methods: {
    getMessage() {
      request({
        url: "/api/customer/message_notice/page",
        method: "post",
        data: {
          page_no: this.currentpage,
          size: this.size,
          status: this.radio == -1 ? null : this.radio,
        },
      }).then((res) => {
        this.messageObj = res.data;
        this.$store.dispatch("app/messageNum").then(() => {});
      });
    },
    statusChange() {
      this.currentpage = 1;
      this.getMessage();
    },
    pageBtn(e) {
      this.currentpage = e;
      this.getMessage();
    },
    isRead(val) {
      if (val.status != 0) return;
      request({
        url: "/api/customer/message_notice/dealStatus",
        method: "post",
        data: {
          id: val.id,
        },
      }).then((res) => {
        this.getMessage();
      });
    },
    readAll() {
      request({
        url: "/api/customer/message_notice/dealAllStatus",
        method: "post",
        data: {},
      }).then((res) => {
        this.getMessage();
      });
    },

    pervious() {
      this.$refs.pageGroup.prev();
    },
    next() {
      this.$refs.pageGroup.next();
    },
    drawerClose() {
      this.messageShow = false;
    },
    login() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    userCenter() {
      this.$router.push("/personalCenter");
    },
    logOff() {
      util.cookies.remove("token"); //删除token
      localStorage.removeItem("user"); //删除用户信息
      this.$store.commit("app/SHOP_CLEAR", 0);
      sessionStorage.removeItem("data");
      this.$router.push("/login");
    },
    personalData() {
      this.$router.push("/personalData");
    },
    purchaseOrder() {
      this.$router.push("/purchaseOrder");
    },
    saleOrder() {
      this.$router.push("/saleOrder");
    },
    commodityManagement() {
      this.$router.push("/commodityManagement");
    },
    customerManagement() {
      this.$router.push("/customerManagement");
    },
    platformSetup() {
      this.$router.push("/platformSetup");
    },

    customerMessage() {
      this.getMessage();
      this.messageShow = true;
      // this.$router.push("/customerMessage");
    },
    shopInformation() {
      this.$router.push("/shopInformation");
    },
    changePassword() {
      this.$router.push("/changePassword");
    },
    addressDelivery() {
      this.$router.push("/addressDelivery");
    },
    termsSale() {
      this.$router.push("/termsSale");
    },
  },
};
</script>

<style lang="scss">
.el-dropdown-menu__item {
  color: #0e0000 !important;
}
</style>
<style lang="scss" scoped>
.twodimensionalcodeStyle {
  text-align: center;
}
.sort {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ff2929;
  ::v-deep .el-radio-button__inner {
    width: 120px;
    height: 38px;
    background: transparent;
    border: none;
    border-left: none !important;
    border-radius: 4px 4px 0 0 !important;
  }
  .el-button {
    width: 72px;
    height: 30px;
    padding: 0;
  }
}
.item {
  ::v-deep .el-badge__content.is-fixed {
    top: 0px !important;
    right: 0px;
  }
}
.top_item {
  ::v-deep .el-badge__content.is-fixed {
    top: 10px !important;
  }
}
.order_drawer {
  .dialog_title {
    span {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}
.mess_card {
  margin-bottom: 16px;
  &.is-always-shadow {
    box-shadow: none;
  }
  border: 1px solid #ebeef5;
  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(242, 32, 32, 0.4);
  }
  h4 {
    height: 20px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .el-card__body {
    height: auto !important;
    font-size: 14px;
  }
}
.item {
  ::v-deep .el-badge__content.is-fixed {
    top: 7px;
  }
}
.topStyle {
  // width: calc(100vw - 18px) !important;
  width: 100%;
  background-color: #f4f4f7 !important;
  section {
    width: 1200px;
    margin: 0 auto !important;
    height: 35px;
    line-height: 35px;
    display: flex;
    justify-content: space-between;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        color: #0e0000;
        font {
          color: #f22020;
        }
      }
    }
    div:nth-child(2) {
      font-size: 14px;
      .el-button {
        font-size: 14px;
        padding: 0;
        color: #0e0000;
        &:hover {
          color: #f22020;
        }
        span {
          margin: 0;
        }
      }
      font {
        line-height: 20px;
        color: #0e0000;
      }
    }
  }
  .dropdownStyle {
    color: #0e0000;
    &:hover {
      cursor: pointer;
    }
  }
  .dividerStyle {
    height: 16px !important;
    top: 10px !important;
  }

  @keyframes blink {
    0% {
      // opacity: 1;
      color: #0e0000;
      // font-weight: bold;
    }

    50% {
      // opacity: 0;
      color: #f22020;
      // font-weight: bold;
    }

    100% {
      // opacity: 1;
      color: #0e0000;
      // font-weight: bold;
    }
  }
  .flashStyle {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
</style>
