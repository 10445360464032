<template>
  <div>
    <ul>
      <li @click="routerShopCar">
        <el-badge :value="$store.state.app.num" class="item">
          <i class="iconfont icon-gouwuche"></i>
        </el-badge>
      </li>
      <li class="phone">
        <i class="iconfont icon-dianhua"></i>
        <div class="cont">
          <div style="color: #000">服务热线</div>
          <div class="contPhone">
            <div>027-8228-0285</div>
            <div style="margin-top: -30px">027-8228-0385</div>
          </div>
        </div>
      </li>
      <li class="weixin">
        <i class="iconfont icon-weixin" style="font-size: 32px"></i>

        <div class="img">
          <div style="color: #000">客服微信&ensp;</div>
          <img src="/img/wxcode.png" />
        </div>
      </li>
      <li @click="backTop">
        <i class="iconfont icon-zhidingxian" style="font-size: 28px"></i>
      </li>
      <el-backtop ref="backtop" v-show="false">
        <div><i class="el-icon-upload2"></i></div>
      </el-backtop>
    </ul>
  </div>
</template>

<script>
export default {
  name: "fixedBtn",

  data() {
    return {};
  },

  mounted() {},

  methods: {
    backTop() {
      this.$refs.backtop.handleClick();
    },
    routerShopCar() {
      this.$router.push("/shopCar");
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  position: fixed;
  top: 500px;
  right: 80px;
  z-index: 90;

  ::v-deep .el-badge .el-badge__content {
    top: 15px;
    right: 17px;
  }
  li,
  .el-backtop {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 30px;
    color: #7c7f82;
    background: #ffffff;
    box-shadow: 0px 0px 7px 1px #c3c6c8;
    border-radius: 4px;
    margin-bottom: 16px;
    text-align: center;
    &:hover {
      box-shadow: 0px 0px 7px 1px rgba(242, 32, 32, 0.3);
      color: #f22020;
      cursor: pointer;
    }
  }
  i {
    font-size: 30px;
  }
  .weixin {
    position: relative;
    &:hover .img {
      display: inline-block;
    }
    .img {
      position: absolute;
      top: -72px;
      left: -175px;
      display: none;
    }
  }
  .phone {
    position: relative;
    &:hover .cont {
      display: inline-block;
    }
    .cont {
      position: absolute;
      font-size: 16px;
      top: -10px;
      left: -135px;
      display: none;

      .contPhone {
        position: absolute;
        white-space: nowrap;
        top: 30px;
      }
    }
  }
}
</style>
