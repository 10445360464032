const rbstateFormat = function (cellValue) {
  //金额格式化,保留两位小数
  if (cellValue) {
    cellValue = Number(cellValue).toFixed(2);
    cellValue += "";
    if (!cellValue.includes(".")) cellValue += ".";
    return cellValue
      .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ",";
      })
      .replace(/\.$/, "");
  } else {
    return "0.00";
  }
};
const rbstateFormatNotBack = function (cellValue) {
  //金额格式化,保留两位小数,无数据不返回
  if (cellValue) {
    cellValue = Number(cellValue).toFixed(2);
    cellValue += "";
    if (!cellValue.includes(".")) cellValue += ".";
    return cellValue
      .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ",";
      })
      .replace(/\.$/, "");
  } else {
    return "";
  }
};

const rbstateFormatInteger = function (cellValue) {
  //金额格式化
  if (cellValue) {
    cellValue = Number(cellValue).toFixed(0);
    cellValue += "";
    if (!cellValue.includes(".")) cellValue += ".";
    return cellValue
      .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ",";
      })
      .replace(/\.$/, "");
  } else {
    return "0";
  }
};

const weightFormat = function (val) {
  //重量格式化 保留三位小数
  if (val) {
    return val.toFixed(3);
  } else {
    return "";
  }
};

const NewightFormat = function (val) {
  //重量格式化 保留三位小数且为0显示
  if (val) {
    return val.toFixed(3);
  } else {
    return (0).toFixed(3);
  }
};

const timeFormat = function (date) {
  //日期格式化
  if (date) {
    let timestamp = new Date(date).valueOf();
    var now = new Date(timestamp);
    var nian = now.getFullYear();
    var yue = (now.getMonth() + 1).toString().padStart(2, "0");
    var ri = now.getDate().toString().padStart(2, "0");
    var shi = now.getHours().toString().padStart(2, "0");
    var fen = now.getMinutes().toString().padStart(2, "0");
    var miao = now.getSeconds().toString().padStart(2, "0");
    return `${nian}-${yue}-${ri} ${shi}:${fen}:${miao}`;
  } else {
    return "";
  }
};
const NewtimeFormat = function (date) {
  //日期格式化不要时分秒
  if (date) {
    let timestamp = new Date(date).valueOf();
    var now = new Date(timestamp);
    var nian = now.getFullYear();
    var yue = (now.getMonth() + 1).toString().padStart(2, "0");
    var ri = now.getDate().toString().padStart(2, "0");
    var shi = now.getHours().toString().padStart(2, "0");
    var fen = now.getMinutes().toString().padStart(2, "0");
    var miao = now.getSeconds().toString().padStart(2, "0");
    return `${nian}-${yue}-${ri}`;
  } else {
    return "";
  }
};

const NewYear = function (date) {
  //日期格式化年份
  if (date) {
    let timestamp = new Date(date).valueOf();
    var now = new Date(timestamp);
    var nian = now.getFullYear();
    return `${nian}`;
  } else {
    return "";
  }
};

const hourFormat = function (date) {
  //日期格式化不要时分秒
  if (date) {
    let timestamp = new Date(date).valueOf();
    var now = new Date(timestamp);
    var nian = now.getFullYear();
    var yue = (now.getMonth() + 1).toString().padStart(2, "0");
    var ri = now.getDate().toString().padStart(2, "0");
    var shi = now.getHours().toString().padStart(2, "0");
    var fen = now.getMinutes().toString().padStart(2, "0");
    var miao = now.getSeconds().toString().padStart(2, "0");
    return `${shi}-${fen}-${miao}`;
  } else {
    return "";
  }
};

const toChies = function (n = 0) {
  //将金额转换成中文大写
  var fraction = ["角", "分"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  var unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  var head = n < 0 ? "欠" : "";
  n = Math.abs(n);
  var s = "";
  for (var i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);
  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = "";
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
};
const toFixedNumber = function (cellValue, num) {
  if (cellValue) {
    cellValue *= Math.pow(10, num);
    cellValue = Math.round(cellValue);
    // console.log(cellValue / Math.pow(10, num));
    return cellValue / Math.pow(10, num);
  } else {
    return 0.0;
  }
};
export default {
  rbstateFormat,
  rbstateFormatNotBack,
  timeFormat,
  hourFormat,
  NewtimeFormat,
  weightFormat,
  NewightFormat,
  toChies,
  rbstateFormatInteger,
  toFixedNumber,
  NewYear,
};
