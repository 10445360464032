<template>
  <div>
    <div class="section footer">
      <section>
        <!-- <div style="display: flex"> -->
        <!-- <p><el-link @click="goHelp">帮助中心</el-link></p> -->
        <ul class="help_li">
          <li class="texttitle">{{ treeList1.text }}</li>
          <li v-for="(val, i) in treeList1.children" :key="i">
            <el-link class="textct" @click="goHelp(val.id)">{{
              val.text
            }}</el-link>
          </li>
        </ul>
        <ul class="help_li">
          <li class="texttitle">{{ treeList2.text }}</li>
          <li v-for="(val, i) in treeList2.children" :key="i">
            <el-link class="textct" @click="goHelp(val.id)">{{
              val.text
            }}</el-link>
          </li>
        </ul>
        <ul class="help_li">
          <li class="texttitle">{{ treeList3.text }}</li>
          <li v-for="(val, i) in treeList3.children" :key="i">
            <el-link class="textct" @click="goHelp(val.id)">{{
              val.text
            }}</el-link>
          </li>
        </ul>
        <ul class="help_li">
          <li class="texttitle">{{ treeList4.text }}</li>
          <li v-for="(val, i) in treeList4.children" :key="i">
            <el-link class="textct" @click="goHelp(val.id)">{{
              val.text
            }}</el-link>
          </li>
        </ul>
        <!-- </div> -->
        <div class="contact">
          <p class="texttitle">客服微信</p>
          <img
            style="margin-top: 10px"
            width="100px"
            height="100px"
            src="/img/wxcode.png"
            alt=""
          />
        </div>
        <div class="contact">
          <p class="texttitle">联系方式</p>
          <ul>
            <li>027 - 8228 0285</li>
            <li>027 - 8228 0385</li>
            <li>周一至周五 9:00-18:00</li>
            <li>7*24小时客服在线</li>
          </ul>
        </div>
      </section>
    </div>
    <div class="section bottom">
      <div>
        <span>湖北金广嘉纺织原料有限责任公司Copyright @2022 </span>
        <span class="spanJumpStyle" @click="jump">鄂ICP备2022018079号-2</span>
        <span class="spanJumpStyle" @click="jumpGw">
          技术服务商：武汉杰宜斯信息科技有限公司</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/api/service";
export default {
  name: "homeFooter",

  data() {
    return {
      treeList: [],
      treeList1: {},
      treeList2: {},
      treeList3: {},
      treeList4: {},
      user: {},
    };
  },

  mounted() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.user = JSON.parse(localStorage.getItem("user"));
    } else {
      this.user = {};
    }
    request({
      url: "/api/api/public_help_center/treeMenu",
      method: "post",
    }).then((res) => {
      // console.log(res.datas);
      this.treeList = res.datas;
      this.treeList1 = res.datas[0];
      this.treeList2 = res.datas[1];
      this.treeList3 = res.datas[2];
      this.treeList4 = res.datas[3];
    });
  },

  methods: {
    goHelp(id) {
      // this.$router.push("/helpCenter");
      if (!(this.user.modelType == 1)) {
        this.$router.push({
          path: "/helpCenter",
          query: {
            id,
          },
        });
      } else {
        this.$router.push("/helpCenter");
      }
    },
    jump() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    jumpGw() {
      window.open("https://www.jeesit.com/");
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  color: white;
  background: #403f3e;
  font-size: 14px !important;
  position: relative;
  z-index: 10;
  // position: fixed;
  // bottom: 40px;
  .texttitle {
    font-size: 16px;
    font-weight: bold;
  }
  .textct {
    color: #cecece;
  }
}
section {
  width: 1200px;
  margin: auto;
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  // div {
  // width: 30%;
  .el-link {
    color: #fff;
  }
  .help_li {
    // display: flex;
    // margin-top: 20px;
    // margin-right: 30px;
    list-style: none;
    li:nth-child(1) {
      // font-size: 16px;
      padding: 0;
    }
    li {
      padding-top: 6px;
    }
  }
  // }
  // div:nth-child(1) {
  //   width: 40%;
  // }
  p {
    font-size: 14px;
  }
  .contact {
    // display: flex;
    // text-align: center;
    // margin-left: 200px;
    position: relative;
    right: 0;
    p {
      margin-right: 40px;
    }
    ul {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      li:nth-child(1) {
        font-size: 14px;
      }
      li:nth-child(3) {
        text-align: center;
        background: #f22020;
        border-radius: 4px;
        width: 145px;
      }
      li {
        margin-top: 10px;
      }
    }
  }
}
.bottom {
  background: #333332;
  div {
    width: 1200px;
    margin: 0 auto;
    background: #333332;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
  }
}
.spanJumpStyle {
  cursor: pointer;
}
</style>
