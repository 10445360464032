import axios from "axios";
// import Adapter from "axios-mock-adapter";
import { get } from "lodash";
import util from "@/libs/util";
// import { errorLog, errorCreate } from "./tools";
import { Message } from "element-ui";
import qs from "qs";
/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例

  axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
  axios.defaults.transformRequest = [
    function (data) {
      // let ret = "";
      //   for (let it in data) {
      //     ret +=
      //       encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
      //   }
      return qs.stringify(data, { indices: false });
    },
  ];
  const service = axios.create();
  // 请求拦截
  service.interceptors.request.use(
    (config) => config,
    (error) => {
      // 发送失败
      console.log(error);
      return Promise.reject(error);
    }
  );
  // 响应拦截
  service.interceptors.response.use(
    (response) => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data;
      // 这个状态码是和后端约定的
      const { code } = dataAxios;
      // console.log(code);
      // 根据 code 进行判断
      if (code === 401) {
        //token失效
        window.location.href = "/#/login";
        return;
      } else if (code === 400) {
        //错误提示
        Message({
          showClose: true,
          message: dataAxios.message,
          type: "error",
          offset: 200,
        });
        return Promise.reject(dataAxios.message);
      } else if (code === 403) {
        //无权限
        Message({
          showClose: true,
          message: "暂无权限",
          type: "error",
          offset: 200,
        });
        return Promise.reject(dataAxios.message);
      } else if (code === 415) {
        //警告提示
        // Message.warning(dataAxios.message);
        Message({
          showClose: true,
          message: dataAxios.message,
          type: "warning",
          offset: 200,
        });
        return;
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 200:
            // [ 示例 ] code === 0 代表没有错误
            return dataAxios;
          // case "xxx":
          //   // [ 示例 ] 其它和后台约定的 code
          //   errorCreate(
          //     `[ code: xxx ] ${dataAxios.message}: ${response.config.url}`
          //   );
          //   break;
          default:
            // 不是正确的 code
            return dataAxios;
          // errorCreate(`${dataAxios.message}: ${response.config.url}`);
        }
      }
      // return dataAxios;
    },
    (error) => {
      const status = get(error, "response.status");
      switch (status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
      //   errorLog(error);
      return Promise.reject(error);
    }
  );
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    const token = util.cookies.get("token");
    const configDefault = {
      headers: {
        Authorization: token,
        // Authorization: "",
        "Content-Type": get(
          config,
          "headers.Content-Type",
          "application/x-www-form-urlencoded"
        ),
      },
      timeout: 20000,
      baseURL: process.env.VUE_APP_API,
      data: {},
    };

    return service(Object.assign(configDefault, config));
  };
}

// 用于真实网络请求的实例和请求方法
export const service = createService();
export const request = createRequestFunction(service);

// 用于模拟网络请求的实例和请求方法
// export const serviceForMock = createService();
// export const requestForMock = createRequestFunction(serviceForMock);

// 网络请求数据模拟工具
// export const mock = new Adapter(serviceForMock);
