<template>
  <div class="section">
    <header>
      <div @click="goHome">
        <img src="/img/logo.png" width="50px" alt="" />
        <span
          class="title"
          style="
            font-family: myfont;
            display: inline-block;
            font-weight: normal;
            font-size: 30px;
          "
        >
          <div style="position: relative; top: 11px">金广嘉产业网</div>
        </span>
      </div>
      <el-autocomplete
        v-model.trim="searchData"
        placeholder="批号/供应商/轧花厂/仓库"
        size="medium"
        @keyup.enter.native="searchBatch"
        @clear="searchBatch"
        :fetch-suggestions="querySearchAsync"
        @select="handleSelect"
        style="width: 500px"
      >
        <el-button
          @click="searchBatch"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-autocomplete>
      <div>
        <img
          src="/img/twodimensionalcode.jpg"
          width="100px"
          height="100px"
          alt=""
          style="margin-right: 20px"
        />
        <el-badge :value="$store.state.app.num">
          <i
            @click="myShopCar"
            class="iconfont icon-gouwuche"
            style="margin-right: 8px; font-size: 20px; color: #f71d1d"
          ></i>
        </el-badge>
        <span @click="myShopCar">我的购物车</span>
      </div>
    </header>
    <nav>
      <el-radio-group v-model="radio" @change="navChange">
        <el-radio-button :label="1">首页</el-radio-button>
        <el-radio-button :label="2">棉花超市</el-radio-button>
        <el-radio-button :label="6">涤纶短纤</el-radio-button>
        <el-radio-button :label="3">全疆资源</el-radio-button>
        <el-radio-button :label="4">企业旺铺</el-radio-button>
        <el-radio-button :label="5">升贴水计算</el-radio-button>
      </el-radio-group>
    </nav>
  </div>
</template>

<script>
import { request } from "@/api/service";
export default {
  name: "homeHeader",

  data() {
    return {
      radio: 1,
      searchData: "",
      restaurants: [],
    };
  },
  watch: {
    "$route.path"(toPath, fromPath) {
      // console.log("路由变化了");
      // console.log("当前页面路由地址：" + toPath);
      // console.log("上一个路由地址：" + fromPath);
      if (toPath == "/home") {
        this.radio = 1;
      } else if (toPath == "/cottonMarket") {
        this.radio = 2;
      } else if (toPath == "/searchBatchAll") {
        this.radio = 3;
      } else if (toPath == "/ginMill") {
        this.radio = 4;
      } else if (toPath == "/warehouseReceipt") {
        this.radio = 5;
      } else if (toPath == "/polyester") {
        this.radio = 6;
      } else {
        this.radio = "";
      }
      if (JSON.parse(localStorage.getItem("user"))) {
        this.$store.dispatch("app/messageNum").then(() => {});
      } else {
        this.$store.commit("app/SHOP_CLEAR", 0);
      }
      // console.log(toPath);
      // if (!(toPath == "/searchBatch" || toPath == "/refresh")) {
      //   this.searchData = "";
      // }
    },
  },
  mounted() {
    if (this.$route.meta) {
      switch (this.$route.meta.key) {
        case "home":
          this.radio = 1;
          break;
        case "cottonMarket":
          this.radio = 2;
          break;
        case "searchBatchAll":
          this.radio = 3;
          break;
        case "ginMill":
          this.radio = 4;
          break;
        case "warehouseReceipt":
          this.radio = 5;
          break;
        case "polyester":
          this.radio = 6;
          break;
        default:
          this.radio = "";
          break;
      }
    }
    if (this.$route.query) {
      this.searchData = this.$route.query.condition;
    }
  },

  methods: {
    navChange() {
      switch (this.radio) {
        case 1:
          this.$router.push("/home");
          break;
        case 2:
          this.$router.push("/cottonMarket");
          break;
        case 3:
          this.$router.push("/searchBatchAll");
          break;
        case 4:
          this.$router.push("/ginMill");
          break;
        case 5:
          this.$router.push("/warehouseReceipt");
          break;
        case 6:
          this.$router.push("/polyester");
          break;
        default:
          break;
      }
    },
    myShopCar() {
      this.radio = "";
      this.$router.push("/shopCar");
    },
    login() {
      this.$router.push("/login");
    },
    goHome() {
      this.$router.push("/");
    },
    searchBatch() {
      // console.log(this.searchData);
      if (this.$route.name == "cottonMarket") {
        this.$router.replace({
          path: "cottonMarket",
          query: {
            condition: this.searchData,
          },
        });
        this.refresh();
      } else {
        this.$router.push({
          path: "cottonMarket",
          query: {
            condition: this.searchData,
          },
        });
      }
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        request({
          url: "/api/api/customer/resource/getSupplierPullData",
          method: "post",
          data: {
            mixture: queryString,
          },
        }).then((res) => {
          cb(res.data);
        });
      } else {
        cb([]);
      }
    },
    handleSelect(item) {
      // console.log(item);
    },
    refresh() {
      //刷新
      this.$router.push("/refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  width: calc(100vw - 18px) !important;
  height: 140px;
  background-color: #fff;
  border-bottom: 2px solid #f22020;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ::v-deep .el-badge {
    margin-right: 8px;
    .el-badge__content {
      right: 17px;
    }
  }
  header,
  nav {
    width: 1200px;
    margin: 0 auto;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70%;
    .title {
      height: 36px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 5px;
      color: #f22020;
      line-height: 20px;
      margin-left: 15px;
      // font-family: "fangsong";
    }
    div {
      display: flex;
      align-items: center;
      i,
      img,
      span {
        cursor: pointer;
      }
    }
    // ::v-deep .input-with-select {
    //   width: 500px;
    //   .el-input__inner {
    //     border: 1px solid #f22020;
    //     border-radius: 4px 0 0 4px;
    //   }
    //   .el-input-group__append {
    //     color: #fff;
    //     background: #F22020;
    //     border-color:#F22020;
    //     .el-button {
    //       position: relative;
    //       top: 1px;
    //       color: #fff;
    //       background: #f22020;
    //       font-size: 22px;
    //       padding: 0;
    //       height: 40px;
    //       width: 48px;
    //       line-height: 40px;
    //       border-radius: 0 4px 4px 0;
    //     }
    //   }
    // }
    .el-input {
      display: inline-table;
      width: 500px;
      ::v-deep .el-input__inner {
        border-color: #f22020;
        // border-radius: 4px 0 0 4px;
      }
      // ::v-deep .el-input-group__append {
      //   font-size: 20px;
      // }
      .el-button {
        // height: 40px;
        width: 48px;
        font-size: 20px;
        padding: 12px;
        // color: #fff;
        // background: #F22020;
        // border-color:#F22020;
        // border-radius: 0 4px 4px 0;
      }
    }
    // ::v-deep .el-input-group__append{
    //   color: #fff;
    //   background: #F22020;
    //   border-color:#F22020;
    // }
  }
  nav {
    .el-radio-group {
      width: 100%;
      ::v-deep .el-radio-button {
        width: 16.5%;
        text-align: center;
        .el-radio-button__inner {
          font-size: 16px;
          width: 100%;
          border: none;
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
}
</style>
