import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
Vue.use(Router);

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require("@/libs/util.import." + process.env.NODE_ENV);
// console.log(_import);

const frameIn = [
  {
    path: "/",
    component: Layout,
    redirect: "home",
    children: [
      // 首页
      {
        path: "home",
        name: "home",
        meta: {
          auth: true,
          cache: true,
          title: "首页",
          key: "home",
        },
        component: _import("home"),
      },
      // 棉花超市
      {
        path: "cottonMarket",
        name: "cottonMarket",
        meta: {
          auth: true,
          cache: true,
          title: "棉花超市",
          key: "cottonMarket",
        },
        component: _import("cottonMarket"),
      },
      // 涤纶短纤
      {
        path: "polyester",
        name: "polyester",
        meta: {
          auth: true,
          cache: true,
          title: "涤纶短纤",
          key: "polyester",
        },
        component: _import("polyester"),
      },
      // 轧花厂
      {
        path: "ginMill",
        name: "ginMill",
        meta: {
          auth: true,
          cache: true,
          title: "轧花厂",
          key: "ginMill",
        },
        component: _import("ginMill"),
      },
      // 仓单查询
      {
        path: "warehouseReceipt",
        name: "warehouseReceipt",
        meta: {
          auth: true,
          cache: true,
          title: "仓单查询",
          key: "warehouseReceipt",
        },
        component: _import("warehouseReceipt"),
      },
      // 购物车
      {
        path: "shopCar",
        name: "shopCar",
        meta: {
          auth: true,
          cache: true,
          title: "购物车",
          key: "shopCar",
        },
        component: _import("shopCar"),
      },
      // 购物车jgj
      {
        path: "shopCarJ",
        name: "shopCarJ",
        meta: {
          auth: true,
          cache: true,
          title: "购物车",
          key: "shopCarJ",
        },
        component: _import("shopCarJ"),
      },
      // 个人中心
      {
        path: "personalCenter",
        name: "personalCenter",
        meta: {
          auth: true,
          cache: true,
          title: "个人中心",
          key: "personalCenter",
        },
        component: _import("personalCenter"),
      },
      // 管理中心
      {
        path: "personalCenterJ",
        name: "personalCenterJ",
        meta: {
          auth: true,
          cache: true,
          title: "管理中心",
          key: "personalCenterJ",
        },
        component: _import("personalCenterJ"),
      },
      // 采购订单
      {
        path: "purchaseOrder",
        name: "purchaseOrder",
        meta: {
          auth: true,
          cache: true,
          title: "采购订单",
          key: "purchaseOrder",
        },
        component: _import("purchaseOrder"),
      },
      // 销售订单
      {
        path: "saleOrder",
        name: "saleOrder",
        meta: {
          auth: true,
          cache: true,
          title: "销售订单",
          key: "saleOrder",
        },
        component: _import("saleOrder"),
      },
      // 销售订单
      {
        path: "saleOrderJ",
        name: "saleOrderJ",
        meta: {
          auth: true,
          cache: true,
          title: "销售订单",
          key: "saleOrderJ",
        },
        component: _import("saleOrderJ"),
      },
      // 商品管理
      {
        path: "commodityManagement",
        name: "commodityManagement",
        meta: {
          auth: true,
          cache: true,
          title: "商品管理",
          key: "commodityManagement",
        },
        component: _import("commodityManagement"),
      },
      // 商品管理jgj
      {
        path: "commodityManagementJ",
        name: "commodityManagementJ",
        meta: {
          auth: true,
          cache: true,
          title: "商品管理",
          key: "commodityManagementJ",
        },
        component: _import("commodityManagementJ"),
      },
      // 客户管理
      {
        path: "customerManagement",
        name: "customerManagement",
        meta: {
          auth: true,
          cache: true,
          title: "客户管理",
          key: "customerManagement",
        },
        component: _import("customerManagement"),
      },
      // 客户管理jgj
      {
        path: "customerManagementJ",
        name: "customerManagementJ",
        meta: {
          auth: true,
          cache: true,
          title: "客户管理",
          key: "customerManagementJ",
        },
        component: _import("customerManagementJ"),
      },
      // 消息通知
      {
        path: "customerMessage",
        name: "customerMessage",
        meta: {
          auth: true,
          cache: true,
          title: "消息通知",
          key: "customerMessage",
        },
        component: _import("customerMessage"),
      },
      // 个人资料
      {
        path: "personalData",
        name: "personalData",
        meta: {
          auth: true,
          cache: true,
          title: "个人资料",
          key: "personalData",
        },
        component: _import("personalData"),
      },
      // 商铺资料
      {
        path: "shopInformation",
        name: "shopInformation",
        meta: {
          auth: true,
          cache: true,
          title: "商铺资料",
          key: "shopInformation",
        },
        component: _import("shopInformation"),
      },
      // 修改密码
      {
        path: "changePassword",
        name: "changePassword",
        meta: {
          auth: true,
          cache: true,
          title: "修改密码",
          key: "changePassword",
        },
        component: _import("changePassword"),
      },
      // 收货地址
      {
        path: "addressDelivery",
        name: "addressDelivery",
        meta: {
          auth: true,
          cache: true,
          title: "收货地址",
          key: "addressDelivery",
        },
        component: _import("addressDelivery"),
      },
      // 销售条款
      {
        path: "termsSale",
        name: "termsSale",
        meta: {
          auth: true,
          cache: true,
          title: "销售条款",
          key: "termsSale",
        },
        component: _import("termsSale"),
      },
      // // 搜索页面
      // {
      //   path: "searchBatch",
      //   name: "searchBatch",
      //   meta: {
      //     auth: true,
      //     cache: true,
      //     title: "搜索页面",
      //     key: "searchBatch",
      //   },
      //   component: _import("searchBatch"),
      // },
      // 搜索所有批号
      {
        path: "searchBatchAll",
        name: "searchBatchAll",
        meta: {
          auth: true,
          cache: true,
          title: "搜索页面",
          key: "searchBatchAll",
        },
        component: _import("searchBatchAll"),
      },
      // 帮助中心
      {
        path: "helpCenter",
        name: "helpCenter",
        meta: {
          auth: true,
          cache: true,
          title: "帮助中心",
          key: "helpCenter",
        },
        component: _import("helpCenter"),
      },
      // 帮助中心jgj
      {
        path: "helpCenterJ",
        name: "helpCenterJ",
        meta: {
          auth: true,
          cache: true,
          title: "帮助中心",
          key: "helpCenterJ",
        },
        component: _import("helpCenterJ"),
      },
      // 平台设置
      {
        path: "platformSetup",
        name: "platformSetup",
        meta: {
          auth: true,
          cache: true,
          title: "平台设置",
          key: "platformSetup",
        },
        component: _import("platformSetup"),
      },
      // 平台设置jgj
      {
        path: "platformSetupJ",
        name: "platformSetupJ",
        meta: {
          auth: true,
          cache: true,
          title: "平台设置",
          key: "platformSetupJ",
        },
        component: _import("platformSetupJ"),
      },
      // 升贴水设置
      {
        path: "premiumWater",
        name: "premiumWater",
        meta: {
          auth: true,
          cache: true,
          title: "升贴水",
          key: "premiumWater",
        },
        component: _import("premiumWater"),
      },

      // 刷新页面 必须保留
      {
        path: "refresh",
        name: "refresh",
        hidden: true,
        component: _import("system/function/refresh"),
      },
    ],
  },
  // 登录
  {
    path: "/login",
    name: "login",
    component: _import("system/login"),
  },
  // 重置密码
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: _import("system/resetPassword"),
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: _import("system/register"),
  },
  //详情打印
  {
    path: "/pending/detail",
    name: "detail",
    meta: {
      auth: true,
      cache: true,
    },
    component: _import("print/detail"),
  },
  //顶部搜索详情打印
  {
    path: "/pending/detailAll",
    name: "detailAll",
    meta: {
      auth: true,
      cache: true,
    },
    component: _import("print/detailAll"),
  },
  // { path: "*", redirect: "/404", hidden: true },
];

/**
 * 错误页面
 */
//  const errorPage = [
//   {
//     path: "*",
//     name: "404",
//     component: _import("system/error/404")
//   }
// ];

// const router = new Router({
//   routes,
// });
// console.log(router);

// export default router;
export default [...frameIn];
