function getUrl() {
  // console.log(process.env.NODE_ENV);
  return process.env.NODE_ENV === "production"
    ? "http://hbjgj.jeesit.cn"
    : "http://192.168.10.122:8080";
}
// 获取财年
function getFiscalYear() {
  var date = new Date();
  var currentdate = "";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  if (month >= 10) {
    currentdate = year + 1;
  } else {
    currentdate = year;
  }
  return currentdate + "";
}
// 获取公司名
function getCorporation() {
  return "湖北金广嘉投资控股股份有限公司";
}
export default {
  getUrl,
  getFiscalYear,
  getCorporation,
};
