<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* 声明全局字体样式 */
@font-face {
  font-family: "myfont"; /* 字体名称 */
  src: url("./assets/fonts/typefacejgj.ttf"); /* 字体文件相对路径 */
}
.navTwo .el-menu {
  background: #ffffff !important;
  box-shadow: 0px 0px 7px 2px rgba(96, 98, 100, 0.2);
  border-radius: 4px;
}

.navTwo .el-menu .el-menu-item {
  background: #ffffff !important;
  color: #000 !important;
  text-align: center;
}
.navTwo .el-menu .el-menu-item.is-active {
  color: #f22020 !important;
  background-color: #fbe9e9 !important;
}
.navTwo .el-menu .el-menu-item:focus,
.navTwo .el-menu .el-menu-item:hover {
  color: #f22020 !important;
  background-color: #fbe9e9 !important;
}
.el-dropdown-menu__item:not(.isdisabled):hover {
  color: #f22020 !important;
  background-color: #fbe9e9 !important;
}
</style>
