import { request } from "@/api/service";

const state = {
  num: 0,
  messagenum: {},
  customer:[],
};
const mutations = {
  SHOP_NUM: (state, payload) => {
    state.num = payload;
  },
  SHOP_CLEAR: (state, payload) => {
    state.num = payload;
  },
  MESSAGE_NUM: (state, payload) => {
    state.messagenum = payload;
  },
  GET_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },
};
const actions = {
  shopNum({ commit }, val) {
    return new Promise((resolve, reject) => {
      request({
        url: "/api/customer/shopping/numCount",
        method: "post",
        data: val,
      })
        .then((res) => {
          //   console.log(res);
          const { data } = res;
          commit("SHOP_NUM", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  messageNum({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/api/customer/message_notice/messageNum",
        method: "post",
      })
        .then((res) => {
          // console.log(res);
          const { data } = res;
          commit("MESSAGE_NUM", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCustomer({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/api/api/public/customer/getSalesman",
        method: "post",
      }).then((res) => {
        const { datas } = res;
        commit("GET_CUSTOMER", datas);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
